import React, {useRef} from 'react'
import {useInView, motion, useAnimation} from 'framer-motion'
// import {useInView} from 'react-intersection-observer'
import Lembut from './assets/Choose us/Lebih Lembut.png'
import Performa from './assets/Choose us/Meningkatkan Performa.png'
import antiseptik from './assets/Choose us/Antiseptik.png'


const ChooseUs = () => {
  const ref = useRef(null)
  const isInView = useInView(ref)

  const chooseContainer = "w-full h-72 lg:h-96 flex flex-col items-center justify-start";
  const detail = "h-full w-auto object-contain";

  const titleContainer = "flex flex-col items-center justify-center my-4 h-20 w-72 border-solid border-2 rounded-full";
  const title = "text-white text-2xl py-3 px-8 font-extrabold";

  const textContainer = "";
  const text = "text-white px-0 sm:px-20 lg:px-12";

  return (
    <div ref={ref} id="chooseus" className='w-full min-h-full flex flex-col justify-center items-center px-20 text-center py-8 '>
      <div className=' w-full py-2 mb-20'>
        <h1 className='md:text-4xl text-3xl font-black yellowText tnr'>WHY CHOOSE US</h1>
      </div>  

      
      <motion.div className='w-full h-full flex flex-col items-center gap-10 lg:gap-10 lg:flex-row' >

        <motion.div className={chooseContainer}
        style={{
          // transform: isInView ? "none" : "translateX(-500px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
        }}>
          <div className='h-24 '>
            <img className={'h-24 '} src={Lembut} alt="" />
          </div>

          <motion.div className={titleContainer} a>
            <h1 className={title} >Lebih Lembut</h1>
          </motion.div>

          <motion.div className={textContainer} >
            <p className={text} >Kombinasi Polyeste rmenciptakan tissue lebih tebal dan lembut sehingga meminimalkan resiko lecet pada kulit </p>
          </motion.div>
        </motion.div>

        <motion.div className={chooseContainer} 
        style={{
          // transform: isInView ? "none" : "translateX(-500px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
        }}>
          <div className='h-24'>
            <img className={'h-24'} src={Performa} alt="" />
          </div>
          
          <motion.div className={titleContainer} >
            <h1 className={title}>Meningkatkan Performa</h1>
          </motion.div>

          <motion.div className={textContainer} >
            <p className={text} >Dengan rasa kebas yang minimal, menciptakan sensasi yang lebih tahan lama </p>
          </motion.div>
        </motion.div>

        <motion.div className={chooseContainer }
        style={{
          // transform: isInView ? "none" : "translateX(-500px)",
          opacity: isInView ? 1 : 0,
          transition: "all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
        }}>
          <div className='h-24 items-center justify-center flex'>
            <img className={'h-10'} src={antiseptik} alt="" />
          </div>
          
          <motion.div className={titleContainer} >
            <h1 className={title} >Plus Antiseptik</h1>
          </motion.div>

          <motion.div className={textContainer} >
            <p className={text} >Antiseptik yang berguna untuk menghilangkan kuman, bakteri maupun virus </p>
          </motion.div>
        </motion.div>
      </motion.div>
        
    </div>
  )
}

export default ChooseUs
