import React from 'react'
import Kinglogo from './assets/Logo.webp'
import Whatsapp from './assets/sosmed logos/whatsapp.png'
import Tiktok from './assets/sosmed logos/tiktok.png'
import Instagram from './assets/sosmed logos/instagram.png'
import Facebook from './assets/sosmed logos/facebook.png'
import Twitter from './assets/sosmed logos/twitter.png'

const footer = () => {
  return (
    <div id='contact' className='flex flex-col md:flex-row justify-between bg-black items-start md:items-center px-10 py-10 gap-7'>

      <div className='flex md:hidden flex-row justify-center items-center gap-6 text-white text-sm '>
        <div className='flex flex-col gap-3'>
          <h1 className='text-xs opacity-50'>Contact</h1>
          <div className='flex flex-row gap-8'>
            <a href="">
              <img className='md:min-w-[8px] w-10' src={Whatsapp} alt="" />
            </a>
           
            <a href="">
              <img className='md:min-w-[8px] w-10' src={Tiktok} alt="" />
            </a>

            <a href="">
              <img className='md:min-w-[8px] w-10' src={Instagram} alt="" />
            </a>

            <a href="">
              <img className='md:min-w-[8px] w-10' src={Facebook} alt="" />
            </a>

            <a href="">
              <img className='md:min-w-[8px] w-10' src={Twitter} alt="" />
            </a>
          </div>
        </div>
      </div>

      <hr className='block w-full md:hidden h-1'/>

      <div className='flex flex-row md:justify-start w-full justify-between items-center gap-6 text-white text-sm'>
        
        <div className='flex flex-col gap-3'>
          <h1 className='text-xs opacity-50'>Email</h1>
          <h1 className=''>kinghorse.official@gmail.com</h1>
        </div>
        
        <div className='flex flex-col gap-3'>
          <h1 className='text-xs opacity-50'>Copyright</h1>
          <h2 className=''> © 2022 - All Rights Reserved </h2>
        </div>
        
      </div>

      {/* <div>
        <img className='w-auto h-10 lg:h-10' src={Kinglogo} alt="" />
      </div> */}
      <div className='hidden md:flex flex-row justify-center items-center gap-6 text-white text-sm '>
        <div className='flex flex-col gap-3'>
          <h1 className='text-xs opacity-50'>Contact</h1>
          <div className='flex flex-row gap-8'>
            <a href="https://wa.me/6281398391996" target={'_blank'}>
              <img className='md:min-w-[8px] w-10' src={Whatsapp} alt="" />
            </a>
           
            <a href="https://www.tiktok.com/@kinghorsemagic?_t=8azWwbJwmT0&_r=1" target={'_blank'}>
              <img className='md:min-w-[8px] w-10' src={Tiktok} alt="" />
            </a>

            <a href="https://www.instagram.com/kinghorse.id/?igshid=ZDdkNTZiNTM%3D" target={'_blank'}>
              <img className='md:min-w-[8px] w-10' src={Instagram} alt="" />
            </a>

            <a href="https://www.facebook.com/Kinghorseofficial" target={'_blank'}>
              <img className='md:min-w-[8px] w-10' src={Facebook} alt="" />
            </a>

            <a href="https://twitter.com/KingHorse_" target={'_blank'}>
              <img className='md:min-w-[8px] w-10' src={Twitter} alt="" />
            </a>
          </div>
        </div>
      </div>
        
        
    </div>
  )
}

export default footer