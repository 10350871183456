import React, { useEffect, useState } from "react";
import About from "../About";
import Video from "../assets/KING HORSE LOGO WEBSITE.mp4"
import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from "framer-motion";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectFade } from "swiper";
// import About from "../About";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/effect-fade";

const Home = () => {
  let { scrollYProgress } = useScroll();
  let y = useTransform(scrollYProgress, [0, 1], ["0%", "140%"]);

  return (
    <motion.div
      id="home"
      className="flex flex-col w-full h-screen overflow-hidden home relative mb-48"
      transition={{ type: "spring", duration: 0.03 }}
    >
      <div className="absolute w-full h-screen z-10">
        <Swiper
          className="absolute w-full h-full my-16"
          effect={"fade"}
          spaceBetween={0}
          slidesPerView={1}
          modules={[Navigation, Autoplay, EffectFade]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={500}
          loop={true}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide className="relative text-center border-slate-500">
            <motion.video
              autoPlay
              loop
              muted
              style={{ y }}
              className="object-cover object-center w-full h-full sm:pt-12 pb-32 pointer-events-none"
              src={Video}
              alt=""
            ></motion.video>
            <div className="px-6 sm:px-0 left-0 right-0 mx-auto absolute  sm:ml-12 sm:transform sm:-translate-y-1/2 w-96 top-1/3">
              {/* <h1 className="text-3xl sm:text-4xl  font-black text-center sm:text-left text-white lg:text-7xl">
                CONFIDENCE BRING PLEASURE
              </h1> */}
            </div>
          </SwiperSlide>
          {/* <SwiperSlide className="relative text-center border-slate-500">
            <motion.img
              style={{ y }}
              className="object-cover  w-full h-full pt-10 pb-32 pointer-events-none"
              src={require("../assets/slider 2.webp")}
              alt=""
            />
            <div className="px-6 sm:px-0 left-0 md:w-[800px] right-0 mx-auto absolute  sm:ml-12 sm:transform sm:-translate-y-1/2 w-96 top-1/3">
              <h1 className="text-2xl sm:text-4xl  font-black text-center sm:text-left text-white lg:text-7xl">
                I WILL ACCOMPANY YOU DURING YOUR INTIMATE MOMENT
              </h1>
            </div>
           
          </SwiperSlide> */}
        </Swiper>
      </div>
      {/* <About /> */}

      {/* <div className='h-1/3'/>
        <div className='h-1/3'/>
        <About />  */}
      {/* <div className={'h-1/3 flex justify-between px-8 lg:px-16 text-left items-center' + hide}>
          <div className='w-96'>
            <h1 className='text-2xl font-black text-white lg:text-4xl'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet temporibus saepe est placeat </h1>
          </div>
          <button onClick={() => setslider('slider2')}><AiFillCaretRight className='text-white text-7xl'/></button>
        </div>

        <div className={'h-1/3 flex justify-between px-8 lg:px-16 text-right items-center' + switchslider}>
          <button onClick={() => setslider('slider1')}><AiFillCaretLeft className='text-white text-7xl'/></button>
          <div className='w-96'>
            <h1 className='text-2xl font-black text-white lg:text-4xl'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet temporibus saepe est placeat </h1>
          </div>
        </div>  */}
    </motion.div>
  );
};

export default Home;
