import Lazada from "../assets/How to buy/LAZADA.png";
import Shopee from "../assets/How to buy/SHOPEE.png";
import Tokped from "../assets/How to buy/TOKPED.png";
import { motion } from "framer-motion";

const Ecommerce = () => {
  return (
    <div className="flex flex-row gap-10 lg:gap-28 justify-center items-end w-[80%]">
      <div className="sm:min-w-[100px] md:min-w-[100px] md:max-w-[150px] flex justify-center items-center">
        <motion.img
          src={Lazada}
          alt=""
          whileHover={{scale:1.1, transition:0.1 }}
          whileTap={{scale:1.2}}
        />
      </div>
      <div className="sm:min-w-[140px] md:min-w-[200px] md:max-w-[210px] flex justify-center items-center ">
        <a href="https://www.tokopedia.com/kinghorse/">
          <motion.img
            className="w-full h-full object-contain"
            src={Tokped}
            alt=""
            whileHover={{scale:1.1, transition:0.1 }}
            whileTap={{scale:1.2}}
          />
        </a>
      </div>
      <div className="sm:min-w-[100px] md:min-w-[100px] md:max-w-[150px] flex justify-center items-center">
        <a href="https://shopee.co.id/kinghorse.official?categoryId=100001&entryPoint=ShopByPDP&itemId=22830020924">
          <motion.img
            src={Shopee}
            alt=""
            whileHover={{scale:1.1, transition:0.1 }}
            whileTap={{scale:1.2}}
          />
        </a>
      </div>
    </div>
  );
};

export default Ecommerce;
