import Ecommerce from "./Ecommerce";
import HowToBuy from "./HowToBuy";

const How = () => {
  return (
    <div id="howtobuy" className="w-full flex flex-col justify-center items-center gap-12 mb-10 mt-14 sm:mb-24 sm:mt-24" >
      <HowToBuy />
      <Ecommerce />
    </div>
  );
};

export default How;
