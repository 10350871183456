import React from 'react'
import {motion, useScroll} from 'framer-motion'


const ProgressBar = () => {
    const { scrollYProgress } = useScroll();
  return (
    <div>
        <motion.div className="progress-bar"
          style={{ scaleX: scrollYProgress }}>


        </motion.div>
        
        
    </div>
  )
}

export default ProgressBar