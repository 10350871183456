import React, { useEffect } from "react";
import ImagePhone from "../assets/product.webp";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AboutProduct = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });
  const animationText = useAnimation();
  const animationImage = useAnimation();

  useEffect(() => {
    if (inView) {
      animationText.start({
        x: 0,
        opacity: 1,
        scale: 1,
        transition: {
          type: "linear",
          duration: 0.6,
        },
      });
    }
    if (!inView) {
      animationText.start({ x: "-100vw", opacity: 0 });
    }
  }, [inView]);

  useEffect(() => {
    if (inView) {
      animationImage.start({
        x: 0,
        opacity: 1,
        scale: 1,
        transition: {
          type: "linear",
          duration: 0.6,
        },
      });
    }
    if (!inView) {
      animationImage.start({ x: "-50vw", opacity: 0 });
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className="aboutProduct  relative overflow-hidden min-w-screen min-h-screen flex flex-col lg:flex-row "
    >
      <motion.div className="productImageContainer text-center justify-center items-center flex  lg:w-1/2 ">
        <img
          className="productImage min-w-screen md:w-[500px] object-contain  "
          src={ImagePhone}
          alt=""
        />
        <motion.img
          className="block lg:hidden lg:absolute  max-h-80 object-contain  "
          src={ImagePhone}
          alt=""
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.4 },
          }}
          whileTap={{ scale: 0.9 }}
        />
      </motion.div>
      <motion.div
        className="w-full z-10 lg:w-1/2 min-h-full max-h-full flex flex-col justify-center items-center lg:px-3 xl:px-14 lg:pr-16 lg:pl-24 mb-16 px-10 mt-6 text-center"
        // animate={animationText}
      >
        <h1 className="font-bold text-3xl text-white tnr">ABOUT OUR PRODUCT</h1>
        <p className="text-white py-8 text-sm sm:text-xl">
          Produk <span className="yellowText">KING HORSE MAGIC</span> adalah
          produk tissue basah untuk dewasa yang dibuat lebih modern dan memiliki
          banyak keunggulan, agar penggunanya dapat merasakan sensasi yang
          berbeda dan meningkatkan kepercayaan diri saat bercinta dengan
          pasangan.
        </p>
        {/* <button className='bg-white py-4 px-14 rounded-full'><a className='text-black font-bold text-xl' href="#">READ MORE</a></button> */}
      </motion.div>
    </div>
  );
};

export default AboutProduct;
