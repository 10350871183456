import Navbar from './components/navbar/Navbar'
import Home from './components/Home/Home'
import About from './components/About'
import AboutProduct from './components/AboutProduct/AboutProduct'
import ChooseUs from './components/ChooseUs'
import ProgressBar from './components/ProgressBar/ProgressBar'
import Footer from './components/Footer'
import How from './components/howtobuy/How'

import {animate, motion} from 'framer-motion'



function App() {
  // useEffect(() => {
  //   window.onbeforeunload = function () {
  //     window.scrollTo(0, 0);
  //   }
  // });
 
  return (
    <motion.div className="flex flex-col w-auto h-auto overflow-hidden">


      {/* <IntroScreen /> */}
      {/* <HomeImage/> */}
      <Navbar />
      <ProgressBar />
      <Home className="overflow-x-hidden"/>
      <About />
      <AboutProduct />
      <ChooseUs />
      <How/>
      {/* <Location /> */}
      {/* <Contact /> */}
      <Footer />

    </motion.div>
  );
}



export default App;
