import { motion, spring } from "framer-motion";
import Kinglogo from "../assets/Logo.webp";
import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  let Links = [
    { name: "HOME", Link: "#home" },
    { name: "ABOUT US", Link: "#aboutus" },
    { name: "WHY CHOOSE US", Link: "#chooseus" },
    { name: "HOW TO BUY", Link: "#howtobuy" },
    { name: "CONTACT", Link: "#contact" },
  ];

  let [open, setopen] = useState(false);

  return (
    <motion.div className="z-20 fixed w-full top-0"
      animate={{transition:spring}}
    >
      <div className="md:flex md:px-10 px-7 justify-between bg-black  md:bg-transparent py-4">
        <div className="font-bold flex cursor-pointer items-center">
          <span>
            <img
              className="h-10 w-10"
              src={Kinglogo}
              alt=""
            />
          </span>
        </div>
        <div
          onClick={() => setopen(!open)}
          className="text-2xl text-white absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <AiOutlineMenu name={open ? "close" : "menu"} />
        </div>
        <ul
          className={`md:flex md:items-center absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0  transition-all duration-500 ease-in ${
            open ? "top-[72px]" : "top-[-450px]"
          }`}
        >
          {Links.map((Link) => (
            <li
              key={Link.name}
              className="coolanimation md:ml-4 lg:ml-8 md:my-0 md:px-3 bg-black hover:bg-white hover:text-black text-white"
            >
              <a
                className="md:pl-0 pl-6 block md:py-1 py-3 font-bold"
                href={Link.Link}
              >
                {Link.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default Navbar;
