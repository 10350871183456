import React from "react";

const HowToBuy = () => {
  return (
    <div className="howTobuy  rounded-full w-60 sm:w-96 py-4 md:py-8 flex items-center justify-center">
      <h1 className="text-black tnr md:text-3xl">HOW TO BUY</h1>
    </div>
  );
};

export default HowToBuy;
