import React from 'react'

import Kinglogo from './assets/Logo.webp'


const About = () => {
  return (
    <div id='aboutus' className=' h-[400px] bottom-0 md:h-[300px] mb-20  px-10 xl:px-56 flex flex-col md:flex-row md:justify-center text-center items-center w-full aboutUs'>
        <div className='w-52 md:mr-10 flex flex-col items-center gap-4 z-10'>
            <img className='w-36 ' src={Kinglogo} alt="" />
            <h1 className='yellowText text-2xl font-black mb-4 z-50 tnr block md:hidden'>ABOUT US</h1>
        </div>

        <div className='mb-12 md:mb-0 text-white text-sm z-10 sm:text-xl md:w-[70%] lg:w-[85%] md:text-xl gap-4 md:text-left md:flex md:flex-col md:justify-center md:items-start '>
            <h1 className='yellowText text-4xl font-black tnr  hidden md:block'>ABOUT US</h1>
            <a className='text-justify'>King horse mengutamakan kenyamanan dan sensasi yang tahan lama. Berbagai kalangan mana saja dapat merasakannya. Dengan menggunakan bahan herbal yang membuat sensasi berbeda dari yang lain.</a>
        </div>
    </div>
  )
}

export default About